import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from 'gatsby'
import ArticleLayout from "../components/articleLayout"
import SEO from "../components/seo"
import { Row, Col, Container, Carousel } from "react-bootstrap"
import { INLINES, BLOCKS, MARKS } from '@contentful/rich-text-types'
import { renderRichText } from "gatsby-source-contentful/rich-text"

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <b className="font-bold">{text}</b>,
  },
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      const { uri } = node.data
      return (
        <a href={uri} className="underline">
          {children}
        </a>
      )
    },
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return (
        <React.Fragment>
          <Col md={1}>
          </Col>
          <Col md={10}>
            <p className="unhistoried-article-paragraph">{children}</p>
          </Col>
          <Col md={1}>
          </Col>
        </React.Fragment>
      )
    },
    [BLOCKS.HEADING_1]: (node, children) => {
      return (
        <React.Fragment>
          <Col md={1}>
          </Col>
          <Col md={10}>
            <h1 className="unhistoried-article-paragraph">{children}</h1>
          </Col>
          <Col md={1}>
          </Col>
        </React.Fragment>
      )
    },
    [BLOCKS.HEADING_2]: (node, children) => {
      return (
        <React.Fragment>
          <Col md={1}>
          </Col>
          <Col md={10}>
            <h2 className="unhistoried-article-paragraph">{children}</h2>
          </Col>
          <Col md={1}>
          </Col>
        </React.Fragment>
      )
    },
    [BLOCKS.HEADING_3]: (node, children) => {
      return (
        <React.Fragment>
          <Col md={1}>
          </Col>
          <Col md={10}>
            <h3 className="unhistoried-article-paragraph">{children}</h3>
          </Col>
          <Col md={1}>
          </Col>
        </React.Fragment>
      )
    },
    [BLOCKS.HEADING_4]: (node, children) => {
      return (
        <React.Fragment>
          <Col md={1}>
          </Col>
          <Col md={10}>
            <h4 className="unhistoried-article-paragraph">{children}</h4>
          </Col>
          <Col md={1}>
          </Col>
        </React.Fragment>
      )
    },
    [BLOCKS.HEADING_5]: (node, children) => {
      return (
        <React.Fragment>
          <Col md={1}>
          </Col>
          <Col md={10}>
            <h5 className="unhistoried-article-paragraph">{children}</h5>
          </Col>
          <Col md={1}>
          </Col>
        </React.Fragment>
      )
    },
    [BLOCKS.HEADING_6]: (node, children) => {
      return (
        <React.Fragment>
          <Col md={1}>
          </Col>
          <Col md={10}>
            <h6 className="unhistoried-article-paragraph">{children}</h6>
          </Col>
          <Col md={1}>
          </Col>
        </React.Fragment>
      )
    },
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { gatsbyImageData, description } = node.data.target
      return (
        <React.Fragment>
          <Col md={1}>
          </Col>
          <Col md={10}>
            <GatsbyImage className="article-image" imgStyle={{objectFit:`contain`}} image={gatsbyImageData} />
          </Col>
          <Col md={1}>
          </Col>
        </React.Fragment>
      )
    },
  },
}

class ArticlePage extends React.Component {
  render() {
    return(
      <React.Fragment>
        <GatsbyImage className="landing-image" image={this.props.data.contentfulArticle.articleFeaturedImage.gatsbyImageData} />
        <ArticleLayout pageInfo={{ pageName: this.props.data.contentfulArticle.articleTitle }}>
          <SEO title={this.props.data.contentfulArticle.articleTitle} keywords={[this.props.data.contentfulArticle.articleTitle]} />
          <Container className="publication-page">
            <Row>
              <Col md={1}></Col>
              <Col md={10}>
                <h1 className="unhistoried-article-title display-5">{this.props.data.contentfulArticle.articleTitle}</h1>
              </Col>
              <Col md={1}></Col>
            </Row>
            <Row className="unhistoried-article-content-row">
              {renderRichText(this.props.data.contentfulArticle.articleContent, options)}
            </Row>
          </Container>
        </ArticleLayout>
      </React.Fragment>

    )
  }
}


export const ArticlePageQuery = graphql`
query ArticleDataQuery($articleTitle: String!) {
  contentfulArticle(
    articleTitle: {eq: $articleTitle}
  ) {
    id
    articleTitle
    articleFeaturedImage {
      gatsbyImageData(layout: FULL_WIDTH)
    }
    articleContent {
      raw
      references {
        ... on ContentfulAsset {
          contentful_id
          title
          description
          gatsbyImageData(layout: FULL_WIDTH)
          __typename
        }
      }
    }
  }
}`

export default ArticlePage
